<template>
  <div>
    <div v-if='isViewMode'>
      {{formattedNumber}}
    </div>
    <input v-else
      type='number'
      v-model='field.value'
      @blur='handleUpdate'
      class='form-input w-full'>
  </div>
</template>

<script>
import numbro from 'numbro'

export default {
  name: 'CustomFieldNumber',
  props: ['field', 'isViewMode'],
  computed: {
    formattedNumber () {
      return (this.field.value) ? numbro(this.field.value).format({ thousandSeparated: true }) : ''
    },
  },
  methods: {
    handleUpdate () {
      this.$emit('update-value', this.field)
    },
  },
}
</script>
